import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import axios from "axios"

import "./contact.css";

import Sign from '../images/contact/sign_contact.png';
import Overlay from "../components/common/overlay"

const ContactPage = () => {

  const [ visibleoverlay, setVisibleoverlay ] = useState(false);
  const [ nowLoading, setNowLoading ] = useState(false);
  const [ causedError, setcausedError ] = useState(false);
  const [ validate, setValidate ] = useState(false);
  const [ formValues, setFormValues ] = useState({
    subject: 'お問い合わせ',
    type: '',
    name: '',
    company: '',
    phone: '',
    mail: '',
    message: '',
  });

  useEffect(() => {
    checkValidate(formValues)
  }, [formValues]);

  const handleClose = () => {
    setcausedError (false);
    setVisibleoverlay(false);
  }

  const handleInput = ({name, value}) => {
    setFormValues({ ...formValues, [name]: value });
  }
  
  const handleSubmit = event => {
    event.preventDefault();
    setNowLoading(true);
    setVisibleoverlay(true);

    axios.post('https://ik-backend.herokuapp.com/onamae/contact', formValues)
    .then(response => {
      setNowLoading(false);
      console.log('response', response);
    })
    .catch(error => {
      setcausedError (true);
      setNowLoading(false);
      console.log('error', error);
    })
  }

  const checkValidate = values => {
    if (
      values.name.length > 0 &&
      values.company.length > 0 &&
      values.phone.length > 0 &&
      values.mail.length > 0 &&
      values.message.length > 0
    ) {
      setValidate(true);
    } else {
      setValidate(false);
    }
  }

  return (
    <Layout>
      <SEO title="CONTACT" />
      <div className="contact-page">
        <div className="content">
          <div className="title">
            <h1>CONTACT</h1>
            <h2 className="ff-min">お問い合わせ</h2>
          </div>
          <form method="POST" onSubmit={handleSubmit}>
            <div className="form-content">
              <div className="form-element">
                <label htmlFor="type">お問い合わせ種別</label>
                <select name="type" id="type" onInput={event => handleInput(event.target)}>
                  <option value="講座、セッションについて">講座、セッションについて</option>
                  <option value="取材や講演について">取材や講演について</option>
                  <option value="企業案件について">企業案件について</option>
                  <option value="その他">その他</option>
                </select>
              </div>
              <div className="form-element">
                <label htmlFor="name">お名前</label>
                <input type="text" name="name" id="name" onInput={event => handleInput(event.target)} />
              </div>
              <div className="form-element">
                <label htmlFor="company">会社名</label>
                <input type="text" name="company" id="company" onInput={event => handleInput(event.target)} />
              </div>
              <div className="form-element">
                <label htmlFor="phone">お電話番号</label>
                <input type="text" name="phone" id="phone" onInput={event => handleInput(event.target)} />
              </div>
              <div className="form-element">
                <label htmlFor="mail">メールアドレス</label>
                <input type="text" name="mail" id="mail" onInput={event => handleInput(event.target)} />
              </div>
              <div className="form-element">
                <label htmlFor="message">お問い合わせ内容</label>
                <textarea id="message" name="message" rows="8" onInput={event => handleInput(event.target)}></textarea>
              </div>
              <div className="btn-holder">
                <button type="submit" disabled={!validate}>送信する</button>
              </div>
            </div>
          </form>
          <div className="sign">
            <img alt="Contact" src={Sign} className="scale-to-fit" />
          </div>
        </div>
      </div>
      { visibleoverlay ? 
        <Overlay nowLoading={nowLoading} error={causedError} handleClose={handleClose} /> 
        : 
        null 
      }
    </Layout>
  )
}

export default ContactPage
